<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            :editable="editable"
            label="관리부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            :editable="editable"
            label="관련공정"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="SOP_FIRE_FIGHTING_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="sopFireFightingTypeCd"
            label='유형'
            v-model="searchParam.sopFireFightingTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-card :title="`[${map.mapName}] 도면 정보`" class="cardClassDetailForm firefighting-card">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div :style="`height: ${zoomer.height}px;`" class="zommer-container col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
          <panZoom v-if="mapExists" ref="panZoom" selector="#zoomerImage" :options="zoomer.options">
            <q-img
              id="zoomerImage"
              :src="map.mapSrc"
              :style="`width: ${(zoomer.height - 30) * map.ratio}px; height: ${zoomer.height - 30}px;`"
            >
              <q-menu
                touch-position
                context-menu
                @before-show="beforeShow"
              >
                <q-list dense style="min-width: 100px">
                  <q-item clickable v-close-popup @click="addFireFighting">
                    <q-item-section avatar class="firefighting-mark-add">
                      <q-icon name="add" color="red" />
                    </q-item-section>
                    <q-item-section>소화기 배치</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
              <q-img
                v-for="(mark, idx) in map.marks"
                :key="idx"
                class="mark-firefighting"
                :style="`transform: translate(${(mark.locationXcoordinate * zoomer.ratio) - 15}px, ${(mark.locationYcoordinate * zoomer.ratio) - 15}px);z-index: auto; user-select: auto; width: 30px; height: 30px;`"
                :src="require('@/assets/images/free-icon-fire-extinguisher-5524203.png')"
              >
                <q-menu 
                  :ref="'markedproxy' + mark.idx" 
                  :content-class="'firefighting-menu-layer'"
                  anchor="top right"
                  self="top left">
                  <q-card 
                    flat bordered 
                    @click="openFireFightingPop(mark)"
                    :class="['firefighting-menu-card']">
                    <q-item class="firefighting-card-section firefighting-card-item">
                      <q-item-section>
                        <q-item-label>
                          <q-badge rounded color="light-blue" :label="mark.sopFireFightingTypeName" /> / 
                          <q-badge rounded color="light-green" :label="mark.sopFireFightingKindName" />
                        </q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-icon name="delete" color="red" class="cursor-pointer" @click.stop="deleteFireFightingMark(mark)" />
                      </q-item-section>
                    </q-item>
                    <q-card-section class="firefighting-card-section cursor-pointer">
                      <q-icon 
                        name="chevron_right" 
                        class="text-indigo" 
                      />
                      <b>{{mark.fireFightingName}}</b>{{mark.manageNo}}
                    </q-card-section>
                  </q-card>
                </q-menu>
              </q-img>
            </q-img>
          </panZoom>
        </div>
        <div :style="`height: ${zoomer.height}px; overflow-y: auto;`" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 firefighting-map-list">
          <q-list bordered padding>
            <template v-for="(mapInfo, idx) in zoomer.maps">
              <q-item :key="idx" clickable @click="mapSelect(mapInfo)">
                <q-item-section class="q-ml-none">
                  <q-img
                    id="zoomerImage"
                    :src="mapInfo.mapSrc"
                    :style="`width: 150px; height: 150px;`"
                  >
                    <div class="absolute-bottom text-subtitle1 text-center">
                      {{mapInfo.mapName}}
                    </div>
                  </q-img>
                </q-item-section>
              </q-item>
              <q-separator v-if="(idx + 1) < zoomer.maps.length" :key="idx + 'sep'" spaced></q-separator>
            </template>
          </q-list>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingLayout',
  components: {
  },
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: null,
        processCd: null,
        sopFireFightingTypeCd: null,
        disposeFlag: 'N'
      },
      zoomer: {
        height: 600,
        ratio: 1,
        style: 'width: 100%; height: 100%;',
        imageStyle: 'width: 90%; height: 90%;',
        value: 100,
        interval: 10,
        options: {
          minZoom: 0.5,
          maxZoom: 3,
          initialZoom: 1,
          initialX: 800,
          initialY: 200,
          bounds: true,
          boundsPadding: 0.1,
          zoomDoubleClickSpeed: 1, 
          onTouch: function(e) {
            return false;
          }
        },
        menu: {
          offsetX: 0,
          offsetY: 0,
        },
        currentMap: {
          sopMapId: ''
        },
        maps: [],
      },
      editable: true,
      listUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  computed: {
    map() {
      let map = {
        sopMapId: '',
        mapSrc: '',
        mapName: '',
        ratio: 0,
        marks: []
      }
      if (this.mapExists) {
        map = this.$_.find(this.zoomer.maps, { sopMapId: this.zoomer.currentMap.sopMapId })
      }
      return map;
    },
    mapExists() {
      return this.zoomer.maps && this.zoomer.maps.length > 0 && Boolean(this.zoomer.currentMap.sopMapId)
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.fft.fireFightingMap.list.url
      this.insertUrl = transactionConfig.sop.fft.fireFightingMap.insert.url;
      this.updateUrl = transactionConfig.sop.fft.fireFightingMap.update.url;
      this.deleteUrl = transactionConfig.sop.fft.fireFightingMap.delete.url;
      // code setting
      // list setting
      this.getList();
      this.setSize();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$set(this.zoomer, 'maps', _result.data)
        if (this.zoomer.maps && this.zoomer.maps.length > 0 && !this.zoomer.currentMap.sopMapId) {
          this.$set(this.zoomer.currentMap, 'sopMapId', this.zoomer.maps[0].sopMapId)
        }
      },);
    },
    setSize() {
      this.zoomer.height = window.innerHeight - 200;
      this.zoomer.ratio = (this.zoomer.height - 30) / 575;
      // this.zoomer.style = `width: 100%; height: ${height}px;`
      // this.zoomer.imageStyle = `width: 90%; height: ${height - 30}px;`
    },
    setStyle(mark) {
      // 너비, 높이가 30px임으로 중간을 맞추기 위해 15px만금 조정
      let style = `transform: translate(${mark.locationXcoordinate - 15}px, ${mark.locationYcoordinate - 15}px);`
        + `z-index: auto; user-select: auto; width: 30px; height: 30px;`
      return style;
    },
    beforeShow(event) {
      let ratio = 575 / (this.zoomer.height - 30)
      this.$set(this.zoomer.menu, 'offsetX', event.offsetX * ratio)
      this.$set(this.zoomer.menu, 'offsetY', event.offsetY * ratio)
    },
    mapSelect(mapInfo) {
      this.$set(this.zoomer.currentMap, 'sopMapId', mapInfo.sopMapId)
    },
    deleteFireFightingMark(mark) {
      this.$http.url = this.$format(this.deleteUrl, mark.sopFireFightingMapId);
      this.$http.type = 'DELETE';
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.getList();
      },);
    },
    addFireFighting() {
      /**
       * @순서
       * 1. 소방설비 팝업 open
       * 2. 설비 단일 선택
       * 3. 저장
       */
      this.popupOptions.title = '소방설비 검색'; // 소방설비 검색
      this.popupOptions.param = {
        type: 'single',
        mapFlag: true,
      };
      this.popupOptions.target = () => import(`${'@/pages/fft/fireFightingPop'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeFireFightingPopup;
    },
    closeFireFightingPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let item = data[0]
        this.$http.url = this.insertUrl;
        this.$http.type = 'POST';
        this.$http.param = {
          sopFireFightingMapId: '',  // 작업위치 번호
          sopMapId: this.map.sopMapId,  // 지도 일련번호
          locationXcoordinate: this.zoomer.menu.offsetX,  // 작업위치 X좌표
          locationYcoordinate: this.zoomer.menu.offsetY,  // 작업위치 Y좌표
          sopFireFightingId: item.sopFireFightingId,  // 소방설비 일련번호
          regUserId: this.$store.getters.user.userId,  // 등록자 ID
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    },
    openFireFightingPop(mark) {
      this.popupOptions.title = '소방설비 상세';
      this.popupOptions.param = {
        sopFireFightingId: mark.sopFireFightingId,
      };
      this.popupOptions.target = () => import(`${'@/pages/fft/fireFightingMasterDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
<style lang="sass">
.custom-zoomer
  border: solid 1px silver
  background-color: white !important
.mark-firefighting
  background: unset !important
  cursor: pointer
    
.firefighting-menu-layer
  border-radius: 10px !important
  max-width: 600px !important
.firefighting-menu-card
  padding-top: 5px
  padding-bottom: 5px
  margin: 6px
  .firefighting-card-section
    padding: 0 10px 0 10px
  .firefighting-card-item
    min-height: auto

.zommer-container
  .vue-pan-zoom-scene
    overflow: auto

.firefighting-card
  .customCardbody
    margin: 0 !important
    padding: 0 !important

.firefighting-map-list
  padding-right: 0 !important
  .q-item
    flex-direction: column
    align-items: center

.firefighting-mark-add
  min-width: 0 !important
  padding: 0 !important
</style>